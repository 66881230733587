body,
html {
  font-size: 16;
  box-sizing: border-box;
}

.App {
  height: 100vh;
  width: 100vw;
  text-align: center;
}

.App-logo {
  height: 100vh;
  width: 100vw;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background: /* gradient can be an image */
    linear-gradient(
      to left,
      rgba(0,240,255,1) 0%,
      rgba(247,197,67,1) 47%,
      rgba(233,95,106,1) 100%
    )
    left
    bottom
    black
    no-repeat;
  background-size:100% 3px ;
  align-items: center;
  background-color: black;
  border-bottom: 3px;
  box-sizing: border-box;
  color: white;
  display: flex;
  flex-direction: row;
  font-size: 0.875rem;
  justify-content: space-between;
  min-height: 56px;
  padding: 8px 12px;
  position: fixed;
  width:  100%;
}

.page-contents {
  width: 100vw;
  height: calc(100vh - 56px);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo {
  height: 16px;
}
